import { Mutations } from '@/store/enums/StoreEnums';
import { Printer } from '@/core/api';
import store from '@/store';

async function init() {
  const { success, data: printerList } = await Printer.getPrinters();
  if (!success) return;
  if (!printerList) return;

  // Load Saved Dimensions
  const printers = printerList.map((printer) => {
    const saved_length = localStorage.getItem(`${printer.name}-length`);
    const saved_width = localStorage.getItem(`${printer.name}-width`);
    const saved_orientation = localStorage.getItem(`${printer.name}-orientation`);
    if (saved_length) printer.length = parseInt(saved_length);
    if (saved_width) printer.width = parseInt(saved_width);
    if (saved_orientation) printer.orientation = parseInt(saved_orientation);
    return printer;
  });

  // Save Printers
  store.commit(Mutations.SET_PRINTERS, printers);

  // Load Recently Selected Printer
  const selected = localStorage.getItem('selectedPrinter');

  if (selected) {
    const match = printers.find((printer) => printer.name === selected);
    if (match) {
      store.commit(Mutations.SET_PRINTER, match);
      return;
    }
  }

  // If previous data doesn't exist, set first printer in list.
  store.commit(Mutations.SET_PRINTER, printers[0]);
}

function setPrinter(printerName: string) {
  const printers = store.getters.PrintersAll;
  const printer = printers.find((p) => p.name === printerName);
  if (printer) {
    store.commit(Mutations.SET_PRINTER, printer);
    localStorage.setItem('selectedPrinter', `${printerName}`);
  }
}

function setSize(printerName: string, length: number, width: number, orientation: number) {
  const printers = store.getters.PrintersAll;
  const printer = printers.find((p) => p.name === printerName);
  if (printer) {
    printer.length = length;
    printer.width = width;
    printer.orientation = orientation;
    store.commit(Mutations.SET_PRINTER, printer);
    localStorage.setItem(`${printerName}-length`, `${length}`);
    localStorage.setItem(`${printerName}-width`, `${width}`);
    localStorage.setItem(`${printerName}-orientation`, `${orientation}`);
  }
}

export default { init, setPrinter, setSize };
