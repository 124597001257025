import { createStore } from 'vuex';
import { config } from 'vuex-module-decorators';

import AuthModule from '@/store/modules/AuthModule';
import BodyModule from '@/store/modules/BodyModule';
import ConfigModule from '@/store/modules/ConfigModule';
import PrinterModule from '@/store/modules/PrinterModule';
import ThemeModeModule from '@/store/modules/ThemeModeModule';
import WmsModule from '@/store/modules/WmsModule';

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    PrinterModule,
    ThemeModeModule,
    WmsModule
  }
});

export default store;
