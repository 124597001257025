import { Mutations } from '@/store/enums/StoreEnums';
import { Fulfillment, Courier } from '@/core/api';
import store from '@/store';

async function init() {
  // Check if data is already loaded.
  if (store.getters.wConfigLoaded) return;

  // Get Fulfillment Centers
  const { data: fc_data, success: fc_success } = await Fulfillment.getFulfillmentCenters();
  if (!fc_success) return;
  // eslint-disable-next-line
  if (!!fc_data) {
    store.commit(Mutations.SET_CENTERS, [...fc_data]);
    store.commit(Mutations.SET_CENTER, fc_data[0]);
  }

  // Get Courier List
  const { data: courier_data, success: courier_success } = await Courier.getCouriers();
  if (courier_success && !!courier_data.length) {
    store.commit(Mutations.SET_COURIERS, [...courier_data]);
  }

  // Get Domestic Courier List
  const { data: domestic_courier_data, success: domestic_courier_success } = await Courier.getDomesticCouriers();
  if (domestic_courier_success && !!domestic_courier_data.length) {
    store.commit(Mutations.SET_DOMESTIC_COURIERS, [...domestic_courier_data]);
  }
}

export default { init };
