import { createApp } from 'vue';
import App from './App.vue';

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router';
import store from './store';
import ElementPlus from 'element-plus';
import i18n from '@/core/plugins/i18n';

//imports for app initialization
import ApiService from '@/core/services/ApiService';
import { initApexCharts } from '@/core/plugins/apexcharts';
import { initInlineSvg } from '@/core/plugins/inline-svg';
import { initVeeValidate } from '@/core/plugins/vee-validate';
import VueDragscroll from 'vue-dragscroll';
import Vue3TouchEvents from 'vue3-touch-events';
import '@/core/plugins/prismjs';

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VueDragscroll);
app.use(i18n);
app.use(Vue3TouchEvents);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.mount('#app');
