enum Actions {
  // Metronic Theme Actions
  ADD_BODY_CLASSNAME = 'addBodyClassName',
  REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
  ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
  REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
  ADD_CLASSNAME = 'addClassName',
  SET_BREADCRUMB_ACTION = 'setBreadcrumbAction',
  SET_THEME_MODE_ACTION = 'setThemeModeAction',
  // AuthModule
  SIGN_IN = 'signIn',
  SIGN_OUT = 'signOut',
  // WmsModule
  INIT_WMS_CONFIG = 'initializeWmsConfigurations',
  // PrinterModule
  INIT_PRINTER = 'initializePrinter'
}

enum Mutations {
  // Metronic Theme Mutations
  SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
  SET_BREADCRUMB_MUTATION = 'setBreadcrumbMutation',
  SET_LAYOUT_CONFIG_PROPERTY = 'setLayoutConfigProperty',
  RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
  OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
  OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',
  SET_THEME_MODE_MUTATION = 'setThemeModeMutation',
  // AuthModule
  SET_USER = 'setUser',
  CLEAR_USER = 'clearUser',
  // WmsModule
  SET_CENTERS = 'setFulfillmentCenters',
  SET_CENTER = 'setFulfillmentCenter',
  SET_COURIERS = 'setCouriers',
  SET_DOMESTIC_COURIERS = 'setDomesticCouriers',
  // PrinterModule
  SET_PRINTERS = 'setPrinters',
  SET_PRINTER = 'setPrinter',
  SET_PAGE_SIZE = 'setPrinterPageSize'
}

export { Actions, Mutations };
