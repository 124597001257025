import type { FulfillmentCenter } from '@/core/interfaces';
import { WmsService } from '@/core/services/ApiService';

// Fulfillment Centers
async function createFulfillmentCenter(form: FulfillmentCenter) {
  return await WmsService.post('fulfillment-center', form);
}

async function getFulfillmentCenter(fc_id: number | string) {
  return await WmsService.get('fulfillment-center', `${fc_id}`);
}

async function getFulfillmentCenters() {
  return await WmsService.query('fulfillment-centers', {});
}

async function updateFulfillmentCenterData(fc_id: number | string, form: FulfillmentCenter) {
  return await WmsService.put(`fulfillment-center/${fc_id}`, form);
}

export default {
  createFulfillmentCenter,
  getFulfillmentCenter,
  updateFulfillmentCenterData,
  getFulfillmentCenters
};
