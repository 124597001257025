import { LsfService } from '@/core/services/ApiService';
import store from '@/store';

interface PreForwardingInboundSearchForm {
  tracking_number: string;
  domesticCourierIds: number[];
  clientIds: number[];
  startSearchDate: string | null;
  endSearchDate: string | null;
}

async function getPreForwardingInboundParcel(form: PreForwardingInboundSearchForm, page = 1, size = 10) {
  return LsfService.query(`inbound/pre-forwarding/${page}`, {
    params: {
      size: size,
      'tracking-number': form.tracking_number,
      'domestic-courier-ids': form.domesticCourierIds.join(','),
      'client-ids': form.clientIds.join(','),
      'start-search-date': form.startSearchDate,
      'end-search-date': form.endSearchDate
    }
  });
}

interface PreForwardingInboundForm {
  client_code?: string | null;
  domestic_courier_id: number;
  fc_id: number;
  started_delivery_at?: string;
  tracking_number: string;
}

async function PreForwardingInbound(form: PreForwardingInboundForm) {
  if (!form.client_code) form.client_code = store.getters.userClient.code;
  if (!form.fc_id) form.fc_id = 1;
  // if (!form.started_delivery_at) form.started_delivery_at = formatDateTime(new Date());
  return LsfService.post('inbound/pre-forwarding', form);
}

async function changeCoverInboundParcel(superiorAssetId: number, inferiorAssetId: number) {
  return LsfService.put(`inbound/inbound-parcel/${inferiorAssetId}/pre-forwarding`, { cover_inbound_parcel_asset_id: superiorAssetId });
}

export { PreForwardingInboundSearchForm, PreForwardingInboundForm };
export default { getPreForwardingInboundParcel, PreForwardingInbound, changeCoverInboundParcel };
