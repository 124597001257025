import { WmsService } from '@/core/services/ApiService';

async function getOrderFulfillmentByOrderNumber(orderNumber: string, systemId: number, clientId: number) {
  return await WmsService.query(`order-fulfillment/order/${orderNumber}`, {
    params: { 'system-id': systemId, 'client-id': clientId }
  });
}

async function getOrderFulfillmentByOutboundParcelId(outbound_parcel_id: number) {
  return await WmsService.get('order-fulfillment/outbound-parcel', `${outbound_parcel_id}`);
}

export default {
  getOrderFulfillmentByOrderNumber,
  getOrderFulfillmentByOutboundParcelId
};
