import { WmsService } from '@/core/services/ApiService';
import type { CubicMeasurement } from '@/core/interfaces';

interface CbmForm extends CubicMeasurement {
  fc_id: number;
}

async function forwardingInbound(trackingNumber: string, courier_id: number, form: CbmForm) {
  form.length;
  form.width;
  form.height;
  return await WmsService.post(`inbound/forwarding/${trackingNumber}/${courier_id}`, form);
}

async function reforwardingInbound(trackingNumber: string, courier_id: number, form: CubicMeasurement) {
  form.length;
  form.width;
  form.height;
  return await WmsService.put(`inbound/forwarding/${trackingNumber}/${courier_id}`, form);
}

export default {
  forwardingInbound,
  reforwardingInbound
};
