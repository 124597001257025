import { WmsService } from '@/core/services/ApiService';
import { Printer } from '@/core/api';
import ZPL from '@/core/helpers/zpl';

async function downloadLabel(outbound_parcel_id, pageNo = 1) {
  const response = await WmsService.get('transport/download/label', `${outbound_parcel_id}`);
  if (response.success) {
    if (response.data.file_type === 'zplii') {
      const zpl_base64 = ZPL.extractPage(response.data.base64, pageNo);
      Printer.printFile(zpl_base64, response.data.file_type);
    } else {
      Printer.printFile(response.data.base64, response.data.file_type);
    }
  }
  return response;
}

export default { downloadLabel };
