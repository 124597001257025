/* eslint-disable @typescript-eslint/no-explicit-any */

import { App } from 'vue';
import axios, { AxiosInstance } from 'axios';
import VueAxios from 'vue-axios';
import AuthService from '@/core/services/AuthService';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import Alert from '@/core/helpers/alert';
import { checkAccess } from '@/core/helpers/access';
// import { useRouter } from 'vue-router';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import router from '../../router';

interface ServerResponse {
  code: number;
  detail_message?: string;
  message: string;
  data: any;
}

interface ApiServiceResponse {
  code: number;
  data: any;
  message: string;
  success: boolean;
}

const sessionValidateCode = [3002, 6002];

//2024.07.05 ADD 김수호 : 토라 프론트엔드를 새로 배포했을때 빌드버전을 env와 version.json을 비교하여 사용자의 페이지를 새로고침하여 새 버전을 받아온다.
const checkVersion = () => {
  fetch('/version.json', {
    method: 'GET',
    headers: {
      'Cache-Control': 'no-cache' // 항상 최신 버전을 가져오도록 설정
    }
  })
    .then((response) => response.json()) //읽어온 데이터를 json으로 변환
    .then((json) => {
      if (json.version !== process.env.VUE_APP_BUILD) {
        Swal.fire({
          title: 'TORA 버전 업데이트',
          html: 'TORA 버전이 업데이트 되었습니다.<br>페이지가 새로고침 됩니다.',
          icon: 'info',
          buttonsStyling: false,
          confirmButtonText: '새로고침',
          customClass: {
            confirmButton: 'btn fw-semibold btn-light-primary'
          },
          timer: 3000,
          timerProgressBar: true
        }).then((result) => {
          //새로고침 버튼을 누르면 바로 새로고침 처리
          if (result.isConfirmed) {
            router.go(0);
          } else {
            // swal.fire에 timer를 줘서 자동으로 닫히게는 했지만 then으로 실행하는 코드는 timer와 관계없이 alert이 뜨고 바로 실행된다.
            // 방안으로 동일한 시간 뒤에 새로고침을 하도록 setTimeout을 사용
            setTimeout(() => {
              router.go(0);
            }, 3000); // 3초 딜레이
          }
        });
      }
    });
};

/**
 * @description service to call HTTP request via Axios
 */
export default class ApiService {
  public static vueInstance: App;

  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_DEFAULT_API_URL;

    // Create Multiple API Handler Classes
    LsfService.init(ApiService.vueInstance.axios.create());
    WmsService.init(ApiService.vueInstance.axios.create());
    LocalService.init(ApiService.vueInstance.axios.create());
  }
}

/**
 * @description service to call HTTP request via Axios
 */
export class LsfService {
  public static axiosInstance: AxiosInstance;

  public static init(instance: AxiosInstance) {
    LsfService.axiosInstance = instance;
    LsfService.axiosInstance.defaults.baseURL = process.env.VUE_APP_LSF_API_URL;
  }

  public static showDebug(action: string, request, response) {
    if (checkAccess([{ system_id: 1, user_level_ids: [1, 2, 3] }])) {
      console.log({
        target: 'lsf-m1',
        action: action,
        request: request,
        response: response
      });
    }
  }

  public static setHeader(): void {
    checkVersion();

    if (process.env.VUE_APP_LSF_SYSTEM_CODE) {
      LsfService.axiosInstance.defaults.headers.common['SYSTEM-CODE'] = process.env.VUE_APP_LSF_SYSTEM_CODE;
    }
    if (AuthService.getCookie('ACCESS_TOKEN')) {
      LsfService.axiosInstance.defaults.headers.common['ACCESS-TOKEN'] = AuthService.getCookie('ACCESS_TOKEN');
    }
    if (AuthService.getCookie('REFRESH_TOKEN')) {
      LsfService.axiosInstance.defaults.headers.common['REFRESH-TOKEN'] = AuthService.getCookie('REFRESH_TOKEN');
    }
    LsfService.axiosInstance.defaults.headers.common['Accept'] = 'application/json';
  }

  public static async query(resource: string, params: AxiosRequestConfig): Promise<ApiServiceResponse> {
    const request = async (resource: string, params: AxiosRequestConfig) => {
      try {
        LsfService.setHeader();
        const serverResponse = (await LsfService.axiosInstance.get(resource, params)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : LsfService.parseCode(data.code),
          success: false
        };
        LsfService.showDebug('query', [resource, params], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource, params);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource, params);
    }
    return response;
  }

  public static async get(resource: string, slug: string): Promise<ApiServiceResponse> {
    const request = async (resource: string, slug: string) => {
      try {
        LsfService.setHeader();
        const serverResponse = (await LsfService.axiosInstance.get(`${resource}/${slug}`)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : LsfService.parseCode(data.code),
          success: false
        };
        LsfService.showDebug('get', [resource, slug], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource, slug);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource, slug);
    }
    return response;
  }

  public static async post(resource: string, params: Record<string, any>): Promise<ApiServiceResponse> {
    const request = async (resource: string, params: Record<string, any>) => {
      try {
        LsfService.setHeader();
        const serverResponse = (await LsfService.axiosInstance.post(`${resource}`, params)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : LsfService.parseCode(data.code),
          success: false
        };
        LsfService.showDebug('post', [resource, params], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource, params);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource, params);
    }
    return response;
  }

  public static async update(resource: string, slug: string, params: AxiosRequestConfig): Promise<ApiServiceResponse> {
    const request = async (resource: string, slug: string, params: AxiosRequestConfig) => {
      try {
        LsfService.setHeader();
        const serverResponse = (await LsfService.axiosInstance.put(`${resource}/${slug}`, params)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : LsfService.parseCode(data.code),
          success: false
        };
        LsfService.showDebug('update', [resource, slug, params], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource, slug, params);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource, slug, params);
    }
    return response;
  }

  public static async put(resource: string, params: Record<string, any>): Promise<ApiServiceResponse> {
    const request = async (resource: string, params: Record<string, any>) => {
      try {
        LsfService.setHeader();
        const serverResponse = (await LsfService.axiosInstance.put(`${resource}`, params)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : LsfService.parseCode(data.code),
          success: false
        };
        LsfService.showDebug('put', [resource, params], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource, params);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource, params);
    }
    return response;
  }

  public static async delete(resource: string): Promise<ApiServiceResponse> {
    const request = async (resource: string) => {
      try {
        LsfService.setHeader();
        const serverResponse = (await LsfService.axiosInstance.delete(resource)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : LsfService.parseCode(data.code),
          success: false
        };
        LsfService.showDebug('delete', [resource], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource);
    }
    return response;
  }

  public static serverCode: Record<number, string> = {
    2000: '요청이 정상적으로 처리되었습니다!',
    3000: 'BAD REQUESTS 에러가 발생했습니다.',
    3001: 'ACCESS TOKEN이 유효하지 않습니다.',
    3002: 'ACCESS TOKEN이 만료되었습니다.',
    3003: 'REFRESH TOKEN이 유효하지 않습니다.',
    3004: 'REFRESH TOKEN이 만료되었습니다.',
    3005: 'ACCESS TOKEN 갱신 중 에러가 발생했습니다.',
    3006: 'ACCESS TOKEN 발행 중 에러가 발생했습니다.',
    3007: 'REFRESH TOKEN 발행 중 에러가 발생했습니다.',
    4000: '요청하신 정보가 없습니다.',
    4001: '요청하신 정보가 없습니다.',
    5000: '서버 에러가 발생했습니다.',
    5001: '로직 에러가 발생했습니다.',
    5002: '서버간 통신 중에 에러가 발생했습니다.',
    6000: '존재하지 않는 계정입니다.',
    6001: '사용자 정보 찾기에 실패했습니다.',
    6002: '사용자 세선 정보 불러오기에 실패했습니다.',
    6003: '비밀번호가 일치하지 않습니다.',
    6004: '활성화 되지 않는 계정입니다.',
    6005: '접근권한이 부족합니다.',
    6006: '이미 존재하는 사용자입니다.'
  };

  public static parseCode(code: number): string {
    let message = '에러가 발생했습니다.';
    if (code in LsfService.serverCode) {
      message = LsfService.serverCode[code];
    }
    return message;
  }
}

/**
 * @description service to call HTTP request via Axios
 */
export class WmsService {
  public static axiosInstance: AxiosInstance;

  public static init(instance: AxiosInstance) {
    WmsService.axiosInstance = instance;
    WmsService.axiosInstance.defaults.baseURL = process.env.VUE_APP_WMS_API_URL;
  }

  public static showDebug(action: string, request, response) {
    if (checkAccess([{ system_id: 2, user_level_ids: [1, 2, 3] }])) {
      console.log({
        target: 'wms',
        action: action,
        request: request,
        response: response
      });
    }
  }

  public static setHeader(): void {
    checkVersion();

    if (AuthService.getCookie('ACCESS_TOKEN')) {
      WmsService.axiosInstance.defaults.headers.common['ACCESS-TOKEN'] = AuthService.getCookie('ACCESS_TOKEN');
    }
    WmsService.axiosInstance.defaults.headers.common['Accept'] = 'application/json';
  }

  public static async query(resource: string, params: AxiosRequestConfig): Promise<ApiServiceResponse> {
    const request = async (resource: string, params: AxiosRequestConfig) => {
      try {
        WmsService.setHeader();
        const serverResponse = (await WmsService.axiosInstance.get(resource, params)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : WmsService.parseCode(data.code),
          success: false
        };
        WmsService.showDebug('query', [resource, params], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource, params);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource, params);
    }
    return response;
  }

  public static async get(resource: string, slug: string): Promise<ApiServiceResponse> {
    const request = async (resource: string, slug: string) => {
      try {
        WmsService.setHeader();
        const serverResponse = (await WmsService.axiosInstance.get(`${resource}/${slug}`)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : WmsService.parseCode(data.code),
          success: false
        };
        WmsService.showDebug('get', [resource, slug], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource, slug);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource, slug);
    }
    return response;
  }

  public static async post(resource: string, params: Record<string, any>): Promise<ApiServiceResponse> {
    const request = async (resource: string, params: Record<string, any>) => {
      try {
        WmsService.setHeader();
        const serverResponse = (await WmsService.axiosInstance.post(`${resource}`, params)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : WmsService.parseCode(data.code),
          success: false
        };
        WmsService.showDebug('post', [resource, params], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource, params);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource, params);
    }
    return response;
  }

  public static async update(resource: string, slug: string, params: AxiosRequestConfig): Promise<ApiServiceResponse> {
    const request = async (resource: string, slug: string, params: AxiosRequestConfig) => {
      try {
        WmsService.setHeader();
        const serverResponse = (await WmsService.axiosInstance.put(`${resource}/${slug}`, params)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : WmsService.parseCode(data.code),
          success: false
        };
        WmsService.showDebug('update', [resource, slug, params], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource, slug, params);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource, slug, params);
    }
    return response;
  }

  public static async put(resource: string, params: Record<string, any>): Promise<ApiServiceResponse> {
    const request = async (resource: string, params: Record<string, any>) => {
      try {
        WmsService.setHeader();
        const serverResponse = (await WmsService.axiosInstance.put(`${resource}`, params)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : WmsService.parseCode(data.code),
          success: false
        };
        WmsService.showDebug('put', [resource, params], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource, params);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource, params);
    }
    return response;
  }

  public static async delete(resource: string): Promise<ApiServiceResponse> {
    const request = async (resource: string) => {
      try {
        WmsService.setHeader();
        const serverResponse = (await WmsService.axiosInstance.delete(resource)) as AxiosResponse;
        const data = serverResponse.data as ServerResponse;
        const response = {
          code: data.code,
          data: data.data,
          message: data.detail_message ? data.detail_message : WmsService.parseCode(data.code),
          success: false
        };
        WmsService.showDebug('delete', [resource], response);
        if (!data.code) throw ' EMPTY SERVER RESPONSE ';
        if (data.code === 2000) response.success = true;
        return response;
      } catch (error) {
        Alert.showAlert(`${resource}`, `${error}`);
        return { code: 503, data: null, message: `${error}`, success: false };
      }
    };
    let response = await request(resource);
    if (sessionValidateCode.includes(response.code)) {
      AuthService.validateSession();
      response = await request(resource);
    }
    return response;
  }

  public static serverCode: Record<number, string> = {
    2000: '요청이 정상적으로 처리되었습니다!',
    3000: 'BAD REQUESTS 에러가 발생했습니다.',
    3001: 'ACCESS TOKEN이 유효하지 않습니다.',
    3002: 'ACCESS TOKEN이 만료되었습니다.',
    3003: 'REFRESH TOKEN이 유효하지 않습니다.',
    3004: 'REFRESH TOKEN이 만료되었습니다.',
    3005: 'ACCESS TOKEN 갱신 중 에러가 발생했습니다.',
    3006: 'ACCESS TOKEN 발행 중 에러가 발생했습니다.',
    4000: '요청하신 정보가 없습니다.',
    4001: '요청하신 정보가 없습니다.',
    4002: '요청하신 정보가 중복됩니다.',
    5000: '서버 에러가 발생했습니다.',
    5001: '로직 에러가 발생했습니다.',
    5002: '서버간 통신 중에 에러가 발생했습니다.',
    5003: '서버가 바쁩니다.',
    6000: '접근권한이 부족합니다.'
  };

  public static parseCode(code: number): string {
    let message = '에러가 발생했습니다.';
    if (code in WmsService.serverCode) {
      message = WmsService.serverCode[code];
    }
    return message;
  }
}

/**
 * @description service to call HTTP request via Axios
 */
export class LocalService {
  public static axiosInstance: AxiosInstance;

  public static init(instance: AxiosInstance) {
    LocalService.axiosInstance = instance;
    LocalService.axiosInstance.defaults.baseURL = process.env.VUE_APP_LOCALHOST_API_URL;
  }

  public static showDebug(action: string, request, response) {
    if (
      checkAccess([
        { system_id: 1, user_level_ids: [1] },
        { system_id: 2, user_level_ids: [1] }
      ])
    ) {
      console.log({
        target: 'localhost',
        action: action,
        request: request,
        response: response
      });
    }
  }

  public static setHeader(): void {
    checkVersion();

    LocalService.axiosInstance.defaults.headers.common['Accept'] = 'application/json';
  }

  public static async query(resource: string, params: AxiosRequestConfig): Promise<ApiServiceResponse> {
    try {
      LocalService.setHeader();
      const serverResponse = (await LocalService.axiosInstance.get(resource, params)) as AxiosResponse;
      const data = serverResponse.data as ServerResponse;
      const response = {
        code: data.code,
        data: data.data,
        message: LocalService.parseCode(data.code),
        success: false
      };
      LocalService.showDebug('query', [resource, params], response);
      if (!data.code) throw ' EMPTY SERVER RESPONSE ';
      if (data.code === 2000) response.success = true;
      return response;
    } catch (error) {
      return { code: 503, data: null, message: `${error}`, success: false };
    }
  }

  public static async get(resource: string, slug: string): Promise<ApiServiceResponse> {
    try {
      LocalService.setHeader();
      const serverResponse = (await LocalService.axiosInstance.get(`${resource}/${slug}`)) as AxiosResponse;
      const data = serverResponse.data as ServerResponse;
      const response = {
        code: data.code,
        data: data.data,
        message: LocalService.parseCode(data.code),
        success: false
      };
      LocalService.showDebug('get', [resource, slug], response);
      if (!data.code) throw ' EMPTY SERVER RESPONSE ';
      if (data.code === 2000) response.success = true;
      return response;
    } catch (error) {
      return { code: 503, data: null, message: `${error}`, success: false };
    }
  }

  public static async post(resource: string, params: Record<string, any>): Promise<ApiServiceResponse> {
    try {
      LocalService.setHeader();
      const serverResponse = (await LocalService.axiosInstance.post(`${resource}`, params)) as AxiosResponse;
      const data = serverResponse.data as ServerResponse;
      const response = {
        code: data.code,
        data: data.data,
        message: LocalService.parseCode(data.code),
        success: false
      };
      LocalService.showDebug('post', [resource, params], response);
      if (!data.code) throw ' EMPTY SERVER RESPONSE ';
      if (data.code === 2000) response.success = true;
      return response;
    } catch (error) {
      Alert.showAlert('로컬 클라이언트와 통신 실패', '프린트 클리언트가 실행되었는지 확인 부탁드립니다.');
      return { code: 503, data: null, message: `${error}`, success: false };
    }
  }

  public static async update(resource: string, slug: string, params: AxiosRequestConfig): Promise<ApiServiceResponse> {
    try {
      LocalService.setHeader();
      const serverResponse = (await LocalService.axiosInstance.put(`${resource}/${slug}`, params)) as AxiosResponse;
      const data = serverResponse.data as ServerResponse;
      const response = {
        code: data.code,
        data: data.data,
        message: LocalService.parseCode(data.code),
        success: false
      };
      LocalService.showDebug('update', [resource, slug, params], response);
      if (!data.code) throw ' EMPTY SERVER RESPONSE ';
      if (data.code === 2000) response.success = true;
      return response;
    } catch (error) {
      Alert.showAlert('로컬 클라이언트와 통신 실패', '프린트 클리언트가 실행되었는지 확인 부탁드립니다.');
      return { code: 503, data: null, message: `${error}`, success: false };
    }
  }

  public static async put(resource: string, params: Record<string, any>): Promise<ApiServiceResponse> {
    try {
      LocalService.setHeader();
      const serverResponse = (await LocalService.axiosInstance.put(`${resource}`, params)) as AxiosResponse;
      const data = serverResponse.data as ServerResponse;
      const response = {
        code: data.code,
        data: data.data,
        message: LocalService.parseCode(data.code),
        success: false
      };
      LocalService.showDebug('put', [resource, params], response);
      if (!data.code) throw ' EMPTY SERVER RESPONSE ';
      if (data.code === 2000) response.success = true;
      return response;
    } catch (error) {
      Alert.showAlert('로컬 클라이언트와 통신 실패', '프린트 클리언트가 실행되었는지 확인 부탁드립니다.');
      return { code: 503, data: null, message: `${error}`, success: false };
    }
  }

  public static async delete(resource: string): Promise<ApiServiceResponse> {
    try {
      LocalService.setHeader();
      const serverResponse = (await LocalService.axiosInstance.delete(resource)) as AxiosResponse;
      const data = serverResponse.data as ServerResponse;
      const response = {
        code: data.code,
        data: data.data,
        message: LocalService.parseCode(data.code),
        success: false
      };
      LocalService.showDebug('delete', [resource], response);
      if (!data.code) throw ' EMPTY SERVER RESPONSE ';
      if (data.code === 2000) response.success = true;
      return response;
    } catch (error) {
      Alert.showAlert('로컬 클라이언트와 통신 실패', '프린트 클리언트가 실행되었는지 확인 부탁드립니다.');
      return { code: 503, data: null, message: `${error}`, success: false };
    }
  }

  public static serverCode: Record<number, string> = {
    2000: '요청이 정상적으로 처리되었습니다!',
    3000: 'BAD REQUESTS 에러가 발생했습니다.',
    4000: '요청하신 정보가 없습니다.',
    4001: '요청하신 정보가 없습니다.',
    4002: '요청하신 정보가 중복됩니다.',
    5000: '서버 에러가 발생했습니다.',
    5001: '로직 에러가 발생했습니다.',
    5002: '서버간 통신 중에 에러가 발생했습니다.',
    5003: '서버가 바쁩니다.'
  };

  public static parseCode(code: number): string {
    let message = '에러가 발생했습니다.';
    if (code in LocalService.serverCode) {
      message = LocalService.serverCode[code];
    }
    return message;
  }
}
