import { WmsService } from '@/core/services/ApiService';

async function registCouriers(name: string) {
  return await WmsService.post('couriers', { name: name });
}

async function getCouriers() {
  return await WmsService.query('couriers', {});
}

async function registDomesticCourier(name: string) {
  return await WmsService.post('domestic-couriers', { name: name });
}

async function getDomesticCouriers() {
  return await WmsService.query('domestic-couriers', {});
}

export default { registCouriers, getCouriers, registDomesticCourier, getDomesticCouriers };
