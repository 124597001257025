import { LsfService } from '@/core/services/ApiService';
import Alert from '@/core/helpers/alert';

interface ServerResponse {
  code: number;
  message: string;
}

interface UserAuth {
  id: number;
  system_id: number;
  user_id: number;
  user_level: string;
}

interface AccessToken {
  access_token: string;
  expired_at: string;
  issued_at: string;
}

interface RefreshToken {
  refresh_token: string;
  expired_at: string;
  issued_at: string;
}

interface AccessTokenResp extends ServerResponse {
  data: {
    user: {
      active: boolean;
      client_id: string;
      client_code: string;
      client_name: string;
      email: string;
      id: number;
      name: string;
      sign_in_id: string;
      user_auths: UserAuth[];
    };
  };
}

async function validateToken(access_token: string) {
  try {
    LsfService.setHeader();
    const config = { headers: { 'ACCESS-TOKEN': `${access_token}` } };
    const response = await LsfService.axiosInstance.get('auth/user/access-token', config);
    const data = response.data as AccessTokenResp;
    if (data.code === 2000) {
      return await validateTokenSession(access_token);
    } else {
      return { code: data.code, data: data.data, success: false };
    }
  } catch (error) {
    Alert.showAlert('TOKEN VALIDATION', `${error}`);
    return { code: 503, data: null, success: false };
  }
}

async function validateTokenSession(access_token: string) {
  try {
    LsfService.setHeader();
    const config = { headers: { 'ACCESS-TOKEN': `${access_token}` } };
    const response = await LsfService.axiosInstance.get('auth/user/access-token-session', config);
    const data = response.data as AccessTokenResp;
    if (data.code === 2000) {
      return { code: data.code, data: data.data, success: true };
    } else {
      return { code: data.code, data: data.data, success: false };
    }
  } catch (error) {
    Alert.showAlert('TOKEN SESSION VALIDATION', `${error}`);
    return { code: 503, data: null, success: false };
  }
}

interface RefreshTokenResp extends ServerResponse {
  data: {
    access_token: AccessToken;
    refresh_token: RefreshToken;
  };
}

async function refreshToken(refresh_token: string) {
  try {
    LsfService.setHeader();
    const config = { headers: { 'REFRESH-TOKEN': `${refresh_token}` } };
    const response = await LsfService.axiosInstance.post('auth/user/refresh-token', {}, config);
    const data = response.data as RefreshTokenResp;
    if (data.code === 2000) {
      return { code: data.code, data: data.data, success: true };
    } else {
      return { code: data.code, data: data.data, success: false };
    }
  } catch (error) {
    Alert.showAlert('TOKEN REFRESH', `${error}`);
    return { code: 503, data: null, success: false };
  }
}

interface SignInReqBody {
  sign_in_id: string;
  password: string;
}

interface SignInResp extends ServerResponse {
  user: {
    active: boolean;
    email: string;
    name: string;
    sign_in_id: string;
    user_auths: UserAuth[];
  };
  access_token: AccessToken;
  refresh_token: RefreshToken;
}

async function signIn(form: SignInReqBody) {
  const response = await LsfService.post('auth/user/sign-in', form);
  response.data as SignInResp;
  return response;
}

async function signOut(access_token) {
  try {
    LsfService.setHeader();
    const config = { headers: { 'ACCESS-TOKEN': `${access_token}` } };
    const response = await LsfService.axiosInstance.delete('auth/user/sign-out', config);
    const data = response.data as ServerResponse;
    if (data.code === 2000) {
      return { code: data.code, data: null, success: true };
    } else {
      return { code: data.code, data: null, success: false };
    }
  } catch (error) {
    Alert.showAlert('SIGN OUT', `${error}`);
    return { code: 503, data: null, success: false };
  }
}

export default {
  validateToken,
  refreshToken,
  signIn,
  signOut
};
