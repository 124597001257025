import { WmsService } from '@/core/services/ApiService';
import type { Rack, RackCode } from '@/core/interfaces';

// Rack Management
async function addRack(fc_id: number | string, form: Rack) {
  return await WmsService.post(`rack?fc-id=${fc_id}`, form);
}

async function getRack(rack_id: number | string) {
  return await WmsService.get('rack', `${rack_id}`);
}

async function getRacks(fc_id: number | string) {
  return await WmsService.query('racks', { params: { 'fc-id': `${fc_id}` } });
}

// Rack Actions
async function handOverCourierRack(rack_id: number) {
  return await WmsService.put(`rack/courier/sent?rack-id=${rack_id}`, {});
}

async function getRackWithInventories(fc_id: number, rack: RackCode) {
  return await WmsService.query('rack/inventories', {
    params: {
      'fc-id': fc_id,
      floor: rack.floor,
      space: rack.space,
      aisle: rack.aisle,
      row: rack.row,
      column: rack.column,
      room: rack.room
    }
  });
}

async function getCourierRackStorageCount(fc_id: number) {
  return await WmsService.query('rack/courier/storage/count', {
    params: { 'fc-id': `${fc_id}` }
  });
}

async function backForwardingRack(trackingNumber: string) {
  return await WmsService.put(`rack/move-inventory/back-forwarding/${trackingNumber}`, {});
}

async function movingForwardingRack(rack_id: number, trackingNumber: string) {
  return await WmsService.put(`rack/move-inventory/forwarding/${trackingNumber}`, { rack_id: rack_id });
}

export default {
  addRack,
  getRack,
  getRacks,
  handOverCourierRack,
  backForwardingRack,
  movingForwardingRack,
  getRackWithInventories,
  getCourierRackStorageCount
};
