import { LsfService } from '@/core/services/ApiService';

async function checkExistUser(signInId) {
  return await LsfService.get('auth/user/existence', `${signInId}`);
}

interface SignUpReqBody {
  email: string;
  name: string;
  password: string;
  sign_up_id: string;
}

interface AddUserAuth {
  system_id: number;
  user_level: number;
}

async function signUp(form: SignUpReqBody) {
  return await LsfService.post('auth/user/sign-up', form);
}

async function getUsers(page: number, size = 25) {
  return await LsfService.query(`auth/users/${page}`, {
    params: { size: size }
  });
}

async function addUserAuth(signInId: string, form: AddUserAuth[]) {
  return await LsfService.put(`auth/user-auth/${signInId}`, form);
}

async function deleteUserAuth(userAuthId: number) {
  return await LsfService.delete(`auth/user-auth/${userAuthId}`);
}

async function updateUserByManagement(signInId: string, form) {
  return await LsfService.put(`auth/user/management/${signInId}`, form);
}

export default {
  checkExistUser,
  signUp,
  getUsers,
  addUserAuth,
  deleteUserAuth,
  updateUserByManagement
};
