import { LocalService } from '@/core/services/ApiService';
import { supported } from '@/core/data/printer';
import store from '@/store';

async function checkAvailability() {
  try {
    const { success } = await LocalService.query('online', {});
    return success ? true : false;
  } catch {
    return false;
  }
}

async function getPrinters() {
  const response = await LocalService.query('printers', {});
  if (response.code !== 503) {
    response.data = response.data.map((printer) => {
      printer.supported = supported.includes(printer.driver);
      return printer;
    });
  }
  return response;
}

async function printLabel(code: string) {
  const printer = store.getters.Printer;
  return await LocalService.post('printer/label', {
    text: code,
    length: printer.length,
    width: printer.width,
    orientation: printer.orientation,
    printer: printer.name
  });
}

async function printFile(file: string, file_type: string) {
  const printer = store.getters.Printer;
  return await LocalService.post('printer/file', {
    file: file,
    file_type: file_type,
    length: printer.length,
    width: printer.width,
    orientation: printer.orientation,
    printer: printer.name
  });
}

export default { checkAvailability, getPrinters, printLabel, printFile };
