const page_regex = /\^XA(.|\n)*?\^XZ/gm;
const page_no_regex = /(\^PQ[0-9])/gm;

const extractPage = (file: string, pageNo = 1) => {
  let page = '';
  const decoded = atob(file);
  const pages = [...decoded.matchAll(page_regex)];
  if (pageNo > pages.length) {
    page = pages[pages.length - 1][0];
  } else {
    page = pages[pageNo - 1][0];
  }
  page = page.replace(page_no_regex, '^PQ1');
  return btoa(page);
};

export default { page_regex, extractPage };
