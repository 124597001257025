import { LsfService } from '@/core/services/ApiService';

interface CreateClientReqBody {
  name: string;
}

async function createClient(form: CreateClientReqBody) {
  return await LsfService.post('client', form);
}

async function getClients(page: number) {
  page;
  return await LsfService.query('clients', {});
}

async function joinClient(clientId: number, signInId: string, auths = {}) {
  return await LsfService.put(`client/${clientId}/join/${signInId}`, auths);
}

export default { createClient, getClients, joinClient };
