import WmsConfigService from '@/core/services/WmsConfigService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';
import type { FulfillmentCenter, Courier, DomesticCourier } from '@/core/interfaces';

export interface WmsConfig {
  isLoaded: boolean;
  fulfillmentCenters: FulfillmentCenter[];
  fulfillmentCenter: FulfillmentCenter;
  couriers: Courier[];
  domesticCouriers: DomesticCourier[];
}

@Module
export default class WmsModule extends VuexModule implements WmsConfig {
  isLoaded = false;
  fulfillmentCenters: FulfillmentCenter[] = [];
  fulfillmentCenter = {} as FulfillmentCenter;
  couriers: Courier[] = [];
  domesticCouriers: DomesticCourier[] = [];

  get wConfigLoaded(): boolean {
    return this.isLoaded;
  }

  get fCenters(): FulfillmentCenter[] {
    return this.fulfillmentCenters;
  }

  get fCenter() {
    if (Object.keys(this.fulfillmentCenter).length) {
      return this.fulfillmentCenter;
    }
    return null;
  }

  get Couriers(): Courier[] {
    return this.couriers;
  }

  get DomesticCouriers(): DomesticCourier[] {
    return this.domesticCouriers;
  }

  @Mutation
  [Mutations.SET_CENTERS](fulfillment_centers: FulfillmentCenter[]) {
    this.fulfillmentCenters = fulfillment_centers;
  }

  @Mutation
  [Mutations.SET_CENTER](fulfillment_center: FulfillmentCenter) {
    this.fulfillmentCenter = fulfillment_center;
  }

  @Mutation
  [Mutations.SET_COURIERS](couriers: Courier[]) {
    this.couriers = couriers;
  }

  @Mutation
  [Mutations.SET_DOMESTIC_COURIERS](domesticCouriers: DomesticCourier[]) {
    this.domesticCouriers = domesticCouriers;
  }

  @Action
  [Actions.INIT_WMS_CONFIG]() {
    return WmsConfigService.init();
  }
}
