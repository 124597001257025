import PrinterConfigService from '@/core/services/PrinterConfigService';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators';

interface Printer {
  name: string;
  driver: string;
  supported: boolean;
  online: boolean;
  width: number;
  height: number;
  orientation: number;
}

interface PrinterConfig {
  printers: Printer[];
  printer: Printer;
}

@Module
export default class PrinterModule extends VuexModule implements PrinterConfig {
  printers: Printer[] = [];
  printer = {} as Printer;

  get PrintersAll(): Printer[] {
    return this.printers;
  }

  get PrintersOnline(): Printer[] {
    return this.printers.filter((printer) => printer.online === true);
  }

  get Printer(): Printer {
    return this.printer;
  }

  @Mutation
  [Mutations.SET_PRINTERS](printers: Printer[]) {
    this.printers = printers;
  }

  @Mutation
  [Mutations.SET_PRINTER](printer: Printer) {
    this.printer = printer;
  }

  @Action
  [Actions.INIT_PRINTER]() {
    return PrinterConfigService.init();
  }
}
