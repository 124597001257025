import type { Sku } from '@/core/interfaces';

export function parseRackCode(text: string) {
  text = text.trim();
  const regexp1 = /([0-9]+)-([A-z]+)-([A-z]+)-([0-9]+)-([0-9]+)-([0-9]+)/gm;
  const regexp2 = /([0-9])([A-z])([A-z])([0-9])([0-9])([0-9])/gm;

  let match: string[] = [];
  const match1 = [...text.matchAll(regexp1)];
  const match2 = [...text.matchAll(regexp2)];
  // eslint-disable-next-line
  if (!!match1.length) match = match1[0];
  // eslint-disable-next-line
  if (!!match2.length) match = match2[0];
  // eslint-disable-next-line
  if (!!match.length) {
    return {
      floor: parseInt(match[1]),
      space: match[2].toUpperCase(),
      aisle: match[3].toUpperCase(),
      row: parseInt(match[4]),
      column: parseInt(match[5]),
      room: parseInt(match[6])
    };
  }
  return null;
}

export function concatRackCode(rc) {
  return `${rc.floor}-${rc.space}-${rc.aisle}-${rc.row}-${rc.column}-${rc.room}`;
}

export function utcDateTime(datetime: string): Date {
  const match = datetime.match(/(\d{4})-(\d{2})-(\d{2})\s?(\d{2}):(\d{2}):(\d{2})/);
  if (match) {
    const utc = Date.UTC(parseInt(match[1]), parseInt(match[2]) - 1, parseInt(match[3]), parseInt(match[4]), parseInt(match[5]), parseInt(match[6]));
    return new Date(utc);
  } else {
    return new Date(datetime);
  }
}

export function formatId(input: string | number): string {
  if (input) {
    return input.toString().padStart(6, '0');
  } else {
    return '';
  }
}

export function formatDate(source: Date, delimiter = '-') {
  const year = source.getFullYear();
  const month = (source.getMonth() + 1).toString().padStart(2, '0');
  const day = source.getDate().toString().padStart(2, '0');

  return [year, month, day].join(delimiter);
}

export function formatDateTime(source: Date) {
  const year = source.getFullYear();
  const month = (source.getMonth() + 1).toString().padStart(2, '0');
  const day = source.getDate().toString().padStart(2, '0');
  const hour = source.getHours().toString().padStart(2, '0');
  const minute = source.getMinutes().toString().padStart(2, '0');
  const second = source.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function concatOptions(sku: Sku) {
  return [sku.option1, sku.option2, sku.option3, sku.option4, sku.option5].filter((x) => {
    return x != null && x != '';
  });
}
