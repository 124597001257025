import MenuItems from '@/core/config/MainMenuConfig';
// import { isMobile } from 'mobile-device-detect';
import store from '@/store';
import Alert from '@/core/helpers/alert';
import AuthService from '@/core/services/AuthService';

interface MenuLevel3 {
  heading: string;
  route: string;
}

interface MenuLevel2 {
  heading: string;
  route: string;
  svgIcon: string;
  sub?: MenuLevel3[];
}

interface Access {
  system_id: number;
  user_level_ids: number[];
}

interface MenuLevel1 {
  access: Access[];
  heading: string;
  route: string;
  pages?: MenuLevel2[];
}

function checkAccess(access): boolean {
  const auths = store.getters.authAccess;
  for (let i = 0; i < access.length; i++) {
    const access_auth = access[i];
    for (let j = 0; j < access_auth.user_level_ids.length; j++) {
      const user_level_id = access_auth.user_level_ids[j];
      const check = auths.findIndex((auth) => {
        return auth.system_id === access_auth.system_id && auth.user_level_id === user_level_id;
      });
      if (check >= 0) return true;
    }
  }
  return false;
}

function home(router) {
  const wmsStaff = checkAccess([{ system_id: 2, user_level_ids: [1, 2, 3, 6, 7] }]);
  const lsfClient = checkAccess([{ system_id: 1, user_level_ids: [4, 5] }]);
  const lsfDeveloper = checkAccess([{ system_id: 1, user_level_ids: [1, 2, 3] }]);
  const wmsClient = checkAccess([{ system_id: 2, user_level_ids: [4, 5] }]);
  if (wmsStaff) {
    router.push({ name: 'inbound-checkin' });
  } else if (lsfClient) {
    router.push({ name: 'forwarding-mgmt' });
  } else if (lsfDeveloper) {
    router.push({ name: 'forwarding-mgmt' });
  } else if (wmsClient) {
    router.push({ name: 'inbound-mgmt' });
  } else {
    Alert.showAlert('접근권한 부족', '요청하신 페이지에 접근 권한이 없으십니다.', 'error', true, 'swal').then(() => {
      AuthService.sessionSignOut();
    });
  }
}

function filteredMenu() {
  const filteredItems: MenuLevel1[] = [];
  const items = MenuItems as MenuLevel1[];
  items.filter((item) => {
    const check = checkAccess(item.access);
    if (check) filteredItems.push(item);
  });
  return filteredItems;
}

export { filteredMenu, home, checkAccess };
